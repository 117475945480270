import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import getTreeUrl from '../../utils/getTreeUrl';
import gatsbyImageData from '../gatsbyImageData';
import styles from './post.module.scss';

export default post => {
  const { title, url, excerpt, featuredImage, hLevel } = post
  const blogUrlPrefix = `/blog/`
  const Title = `h${hLevel}`
  const postUrl = `${blogUrlPrefix}${post.tags[0] ? `${post.tags[0].url}` : ''}${getTreeUrl(post)}`

  return (
    <article className={styles.post}>
      {featuredImage && (
        <Link
          className={styles.imageWrapper}
          to={postUrl}
          data-more="Read article"
        >
          <Img
            className={styles.image}
            fluid={{
              ...gatsbyImageData({
                image: featuredImage,
                sizes: {
                  mobile: `calc(100vw - ${20 / 16}em)`,
                  tablet: `calc((100vw - ${40 / 16}em) / 2 - ${15 / 16}em)`,
                  desktop: `${360 / 16}em`,
                },
                srcSet: ['300', '360', '480', '600', '720', '840', '960'],
              }),
            }}
            alt={featuredImage.alt || title}
            Tag="figure"
            styles={{ position: '' }}
            imgStyle={{ objectFit: '', objectPosition: '' }}
          />
        </Link>
      )}

      <Title className={styles.title}>
        <Link
          to={postUrl}
        >
          {title}
        </Link>
      </Title>

      <div
        className={styles.excerpt}
        dangerouslySetInnerHTML={{
          __html: excerpt,
        }}
      />

      <Link
        to={postUrl}
        className={styles.readmore}
      >
        Read the article
      </Link>
    </article>
  )
}
