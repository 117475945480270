import React from 'react'
import Inner from './inner'
import Button from '../button'

export default ({ isLarge, isWhiteCta, buttonLink, buttonText }) => {
  const buttonVariant = `${isLarge ? '' : 'outline_'}${
    isWhiteCta ? 'secondary' : 'light'
  }`

  return (
    <Inner>
      <Button
        href={buttonLink}
        variant={buttonVariant}
        size={isLarge ? 'large' : 'default'}
      >
        {buttonText}
      </Button>
    </Inner>
  )
}
