export default node => {
  let urlPrefix = ''

  ;(function recursiveGetUrl(node) {
    if (node.treeParent) {
      urlPrefix = `/${node.treeParent.url}` + urlPrefix
      recursiveGetUrl(node.treeParent)
    }
  })(node)

  return urlPrefix + '/' + node.url
}
