import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Section from './section'
import Reasons from './reasons'

const WhySection = ({
  title,
  background,
  buttonText,
  buttonLink,
  image,
  reasons,
}) => (
  <Section
    contentAlignment="why"
    title={title}
    background={background}
    backgroundVariant="absolute"
    mainContent={() => <Reasons data={reasons} />}
    buttonText={buttonText}
    buttonLink={buttonLink}
    image={image}
  />
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsWhyGymradio {
          title
          reasons {
            title
            text
          }
          buttonText
          buttonLink
          image {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
          background {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
        }
      }
    `}
    render={data => <WhySection {...data.datoCmsWhyGymradio} {...props} />}
  />
)
