import React from 'react'
import styles from './scrolldown.module.scss'
import { animateScroll as scroll } from 'react-scroll'

export default () => {
  const scrollPosition = event => {
    event.preventDefault()
    const thisElement = event.target
    const parentSection = thisElement.closest('section')
    return parentSection.offsetHeight
  }

  return (
    <a
      href="#link"
      className={styles.scrolldown}
      onClick={event =>
        scroll.scrollTo(scrollPosition(event), {
          duration: 750,
        })
      }
    >
      <svg
        className={styles.icon}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M12 2c3.309 0 6 2.691 6 6v8c0 3.309-2.691 6-6 6s-6-2.691-6-6v-8c0-3.309 2.691-6 6-6zm0-2c-4.418 0-8 3.582-8 8v8c0 4.418 3.582 8 8 8s8-3.582 8-8v-8c0-4.418-3.582-8-8-8zm0 9c-.829 0-1.5-.672-1.5-1.5s.671-1.5 1.5-1.5 1.5.672 1.5 1.5-.671 1.5-1.5 1.5z" />
      </svg>
    </a>
  )
}
