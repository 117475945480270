import React from 'react'
import styles from './cta.module.scss'

import Background from './background'
import Container from '../container'

export default ({
  isWhiteCta,
  isLarge,
  background,
  children,
  justifyContent,
}) => {
  const sectionBgVariant = isWhiteCta ? styles.whitecta : styles.secondarybg
  const sectionSizeVariant = isLarge ? styles.large : ''
  const createJustifyVariant = () => {
    const classes = justifyContent.split('-')
    return `${classes[0]}${
      classes[1] ? classes[1][0].toUpperCase() + classes[1].slice(1) : ''
    }`
  }

  return (
    <section
      className={`${styles.wrapper} ${sectionBgVariant} ${sectionSizeVariant} ${
        styles[createJustifyVariant()]
      }`}
    >
      <Background background={background} />
      <Container>{children}</Container>
    </section>
  )
}
