import React from 'react'
import Post from './post'
import styles from './wrapper.module.scss'

export default ({ data, hLevel = '3' }) => (
  <div className={styles.wrapper}>
    {data.map((p, key) => (
      <Post key={p.node.originalId} {...p.node} hLevel={hLevel} />
    ))}
  </div>
)
