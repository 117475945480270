import React from 'react'

import Wrapper from './wrapper'
import Title from './title'
import Note from './note'
import Button from './button'

export default ({
  title,
  buttonText,
  buttonLink,
  note,
  justifyContent,
  isWhiteCta,
  isLarge,
  background,
}) => (
  <Wrapper
    isWhiteCta={isWhiteCta}
    isLarge={isLarge}
    justifyContent={justifyContent}
    background={background}
  >
    <Title title={title} className={isLarge ? 'h2' : 'h3'} />

    <Button
      isLarge={isLarge}
      isWhiteCta={isWhiteCta}
      buttonText={buttonText}
      buttonLink={buttonLink}
    />

    <Note note={note} />
  </Wrapper>
)
