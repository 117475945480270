import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Section from './section'
import Albums from './albums'
import Logos from './logos'
import sectionStyles from './section.module.scss'

const MusicSection = ({ musicForEveryOne, licensedMusic }) => (
  <Section
    {...musicForEveryOne}
    contentAlignment="music"
    isDark={true}
    afterSectionContent={() => (
      <>
        <Section
          container={false}
          mainContent={() => <Albums image={musicForEveryOne.albums} />}
        />
        <Section
          title={licensedMusic.title}
          mainContent={() => <Logos data={licensedMusic.logos} />}
          footerContent={() => (
            <div
              className={sectionStyles['section__text']}
              dangerouslySetInnerHTML={{
                __html: licensedMusic.text,
              }}
            />
          )}
        />
      </>
    )}
  />
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsMusicForEveryone {
          title
          text
          buttonText
          buttonLink
          background {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
          albums {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            url
          }
        }

        datoCmsLicensedMusic {
          title
          logos {
            fixed(width: 220) {
              ...GatsbyDatoCmsFixed
            }
            alt
            url
          }
          text
        }
      }
    `}
    render={data => (
      <MusicSection
        musicForEveryOne={data.datoCmsMusicForEveryone}
        licensedMusic={data.datoCmsLicensedMusic}
        {...props}
      />
    )}
  />
)
