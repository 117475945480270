import React from 'react'
import Img from 'gatsby-image'
import styles from './cta.module.scss'
import gatsbyImageData from '../gatsbyImageData'

export default ({ background }) => {
  if (!background) return null

  return (
    <Img
      className={styles.background}
      fluid={{
        ...gatsbyImageData({
          image: background,
          sizes: {
            mobile: `calc(${253 / 16}em * ${background.fluid.aspectRatio})`,
            tablet: `calc(${323 / 16}em * ${background.fluid.aspectRatio})`,
            desktop: `${2560 / 16}em`,
          },
          srcSet: ['1300', '1500', '1700', '1900', '2560'],
        }),
      }}
      alt={background.alt || 'Gymradio'}
      Tag="figure"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
      imgStyle={{
        width: 'auto',
        height: '100%',
        objectFit: '',
        objectPosition: '',
        left: '50%',
        //transform: 'translateX(-50%)',
      }}
    />
  )
}
