import { graphql } from 'gatsby';
import React from 'react';

import Blog from '../components/blog/index.js';
import Division from '../components/division';
import Intro from '../components/intro';
import Layout from '../components/layout';
import Section from '../components/section';
import PageFooter from '../components/sectionFooter';
import MusicSection from '../components/sectionMusic';
import WhySection from '../components/sectionWhy';
import Seo from '../components/seo';
import ModularSectionCompany from '../modularSections/companies';
import ModularSectionCta from '../modularSections/cta';
import ModularSectionMap from '../modularSections/map';

export default ({ data }) => (
 //receive data 
  
  <Layout>  
    <Seo {...data.datoCmsHomepage.seo} />
    <Intro />

    {data.datoCmsHomepage.sections.map((s, key) => {
      if (s.__typename === 'DatoCmsSection') return <Section key={key} {...s} />
      else if (s.__typename === 'DatoCmsWhyGymradioSection')
        return <WhySection key={key} />
      else if (s.__typename === 'DatoCmsMusicSection')
        return <MusicSection key={key} />
      else if (s.__typename === 'DatoCmsCallToActionSection')
        return <ModularSectionCta key={key} {...s} />
      else if (s.__typename === 'DatoCmsMapSection')
        return <ModularSectionMap key={key} {...s} />
      else if (s.__typename === 'DatoCmsCompanyLogoListing')
        return <ModularSectionCompany key={key} {...s} />

      return (
        <div>
          {s.__typename} - This section is not supported yet. Please contact
          your developer.
        </div>
      )
    })}

    <Division {...data.latestBlogPostsDivision}>
      <Blog data={data.latestBlogPosts.edges} />
    </Division>
    <PageFooter />
  </Layout>
)

export const query = graphql`
  query {
    datoCmsHomepage {
      seo {
        title
        description
        image {
          format
          size
          width
          height
          url
        }
      }

      sections {
        ... on DatoCmsSection {
          originalId
          title
          text
          buttonText
          buttonLink
          background {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            title
            url
          }
          contentAlignment
          isDark
        }

        ... on DatoCmsCompanyLogoListing {
          darkBackgroundColor
          company {
            id
            companyName
            link
            logo {
              fixed(width: 300, height: 80) {
                ...GatsbyDatoCmsFixed
              }
              url
              format
            }
          }
        }

        ... on DatoCmsCallToActionSection {
          id
          cta {
            originalId
            title
            note
            buttonText
            buttonLink
            justifyContent
            isWhiteCta
            isLarge
            background {
              fluid(maxWidth: 2560) {
                ...GatsbyDatoCmsFluid
              }
              alt
              title
              url
            }
          }
        }
      }
    }

    latestBlogPostsDivision: datoCmsDivision(
      id: { eq: "DatoCmsDivision-432161-en" }
    ) {
      title
      button {
        text
        link
      }
      background {
        fluid(maxWidth: 2560) {
          ...GatsbyDatoCmsFluid
        }
        alt
        url
      }
    }

    latestBlogPosts: allDatoCmsBlogPost(
      filter: { originalId: { ne: "969745" } }
      sort: { fields: meta___createdAt, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          originalId
          title
          url
          excerpt
          featuredImage {
            fluid(maxWidth: 720) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
          tags {
            url
          }
          treeParent {
            url
            treeParent {
              url
              treeParent {
                url
                treeParent {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
