import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import Container from './container'
import PageHeader from './pageHeader'
import Button from './button'
import SocialIcons from './social'
import ScrollDown from './scrolldown'
import styles from './intro.module.scss'
import gatsbyImageData from './gatsbyImageData'
import removePTags from '../utils/remove-p-tags'

const Intro = ({
  title,
  text,
  buttonText,
  buttonLink,
  image,
  background,
  hashtag,
  buttonBottomText,
}) => {
  return (
    <section className={styles.section}>
      <Img
        fluid={{
          ...gatsbyImageData({
            image: background,
            sizes: {
              mobile: `calc(100vh * ${background.fluid.aspectRatio})`,
              tablet: `calc(100vh * ${background.fluid.aspectRatio})`,
              desktop: `calc(100vh * ${background.fluid.aspectRatio})`,
            },
            srcSet: ['1280', '1600', '1920', '2240', '2560'],
          }),
        }}
        alt={background.alt || title.replace(/<\/?[^>]+(>|$)/g, '')}
        Tag="figure"
        className={styles.background}
        style={{ position: '' }}
        imgStyle={{ objectFit: '', objectPosition: '' }}
        critical={true}
      />

      <div className={styles.inner}>
        <PageHeader currentUrl="home" />
        <Container style={{ minHeight: '1px' }}>
          <div className={styles.content}>
            <figure className={styles.image}>
              <Img
                fluid={{
                  ...gatsbyImageData({
                    image: image,
                    sizes: {
                      mobile: `100vw`,
                      tablet: `calc((100vw - ${40 / 16}em) * 0.8)`,
                      desktop: `${932 / 16}em`,
                    },
                    srcSet: ['360', '480', '600', '720', '840', '932'],
                  }),
                }}
                critical={true}
                alt={image.alt || title.replace(/<\/?[^>]+(>|$)/g, '')}
                imgStyle={{ objectFit: '', objectPosition: '' }}
              />
            </figure>
            <div className={styles.heading}>
              <h1 className={styles.title}>
                <em
                  dangerouslySetInnerHTML={{
                    __html: removePTags(title),
                  }}
                />
              </h1>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
              <Button href={buttonLink} variant="secondary" size="large">
                {buttonText}
              </Button>
              <strong className={styles.btn_text}>{buttonBottomText}</strong>
            </div>
          </div>
        </Container>
        <footer className={styles.footer}>
          <Container>
            <strong className={styles.hashtag}>{hashtag}</strong>
            <ScrollDown />
            <SocialIcons />
          </Container>
        </footer>
      </div>
    </section>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsIntroSection {
          title
          text
          hashtag
          buttonText
          buttonLink
          buttonBottomText
          image {
            alt
            fluid(maxWidth: 932) {
              ...GatsbyDatoCmsFluid_noBase64
            }
            url
          }
          background {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
        }
      }
    `}
    render={(data) => <Intro {...data.datoCmsIntroSection} {...props} />}
  />
)
