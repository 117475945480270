import React from 'react'
import Container from './container'
import styles from './division.module.scss'
import Link from './link'
import Img from 'gatsby-image'
import gatsbyImageData from './gatsbyImageData'
import removePTags from '../utils/remove-p-tags'

export default ({ children, title, button, background, gradient }) => {
  return (
    <section
      className={`${styles.division} ${
        gradient ? styles.divisionGradient : ''
      }`}
    >
      {background && <Background image={background} />}
      <Container>
        <header className={styles.division__header}>
          <h2
            className={styles.division__title}
            dangerouslySetInnerHTML={{
              __html: removePTags(title),
            }}
          />
          {button && <Button {...button} />}
        </header>

        {children}

        {button && (
          <footer className={styles.division__footer}>
            <Button {...button} />
          </footer>
        )}
      </Container>
    </section>
  )
}

const Button = ({ text, link }) => (
  <Link to={link} className={styles.division__button}>
    {text}
  </Link>
)

const Background = ({ image }) => (
  <Img
    fluid={{
      ...gatsbyImageData({
        image,
        sizes: {
          mobile: `calc(100vw * 0.5 * ${image.fluid.aspectRatio})`,
          tablet: `calc(100vw * 0.5 * ${image.fluid.aspectRatio})`,
          desktop: `${2560 / 16}em`,
        },
        srcSet: ['360', '480', '640', '768', '800', '945', '1500', '1900'],
      }),
    }}
    alt={'Gymradio'}
    Tag="figure"
    className={styles.division__background}
    style={{ position: '' }}
    imgStyle={{ objectFit: '', objectPosition: '' }}
  />
)
