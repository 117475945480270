import React from 'react'
import Inner from './inner'
import removePTags from '../../utils/remove-p-tags'

export default ({ note }) => (
  <Inner
    dangerouslySetInnerHTML={{
      __html: removePTags(note),
    }}
  />
)
