import React from 'react'
import styles from './cta.module.scss'
import Inner from './inner'
import removePTags from '../../utils/remove-p-tags'

export default ({ title, className }) => (
  <Inner>
    <h2
      className={`${styles.title} ${className}`}
      dangerouslySetInnerHTML={{
        __html: removePTags(title),
      }}
    />
  </Inner>
)
