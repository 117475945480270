import Img from 'gatsby-image';
import React from 'react';

import styles from './companies.module.scss';
import Container from './container';
import gatsbyImageData from './gatsbyImageData';
import Link from './link';

export default ({ company, darkBackgroundColor }) => (
  <div
    className={`${styles.companies} ${
      darkBackgroundColor ? styles.companiesDark : ''
    }`}
  >
    <Container>
      {company.map((item, key) =>
        item.logo.format === 'svg' ? (
          <figure key={key} className={styles.companies__item}>
            {item.link && item.link !== '' ? (
              <Link to={item.link}>
                <img src={item.logo.url} alt={item.companyName} />
              </Link>
            ) : (
              <div>
                <img src={item.logo.url} alt={item.companyName} />
              </div>
            )}
          </figure>
        ) : item.link && item.link !== '' ? (
          <Link to={item.link}>
            <Img
              key={key}
              className={styles.companies__item}
              fixed={{
                ...gatsbyImageData({
                  image: item.logo,
                  quality: 100,
                }),
              }}
              alt={item.logo.companyName}
              Tag="figure"
              style={{ height: '', width: '' }}
              imgStyle={{
                objectPosition: '',
                objectFit: '',
                position: '',
                height: '',
                width: '',
              }}
            />
          </Link>
        ) : (
          <Img
            key={key}
            className={styles.companies__item}
            fixed={{
              ...gatsbyImageData({
                image: item.logo,
                quality: 100,
              }),
            }}
            alt={item.logo.companyName}
            Tag="figure"
            style={{ height: '', width: '' }}
            imgStyle={{
              objectPosition: '',
              objectFit: '',
              position: '',
              height: '',
              width: '',
            }}
          />
        )
      )}
    </Container>
  </div>
)
