import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styles from './map.module.scss'
import Img from 'gatsby-image'
import gatsbyImageData from './gatsbyImageData'

class Map extends React.Component {
  state = { mapIsLoaded: false }

  loadFrame() {
    this.setState({ mapIsLoaded: true })
  }

  componentDidMount() {
    window.onload = this.loadFrame()
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <Img
          className={styles.placeholder}
          fluid={{
            ...gatsbyImageData({
              image: this.props.placeholder,
              sizes: {
                mobile: `calc(60vh * ${
                  this.props.placeholder.fluid.aspectRatio
                } / 2)`,
                tablet: `calc(60vh * ${
                  this.props.placeholder.fluid.aspectRatio
                } / 2)`,
                desktop: `calc(70vh * ${
                  this.props.placeholder.fluid.aspectRatio
                } / 2)`,
              },
              srcSet: ['480', '640', '800', '960', '1280'],
            }),
          }}
          alt={this.props.placeholder.alt || 'Gymradio workout music'}
          Tag="figure"
        />

        {this.state.mapIsLoaded && (
          <iframe
            className={styles.iframe}
            src={this.props.iframeUrl}
            style={{ opacity: 0 }}
            onLoad={e => (e.target.style.opacity = 1)}
            title="Gymradio map"
          />
        )}
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsGymsMap {
          iframeUrl
          placeholder {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
        }
      }
    `}
    render={data => <Map {...data.datoCmsGymsMap} {...props} />}
  />
)
