import React from 'react'
import styles from './reasons.module.scss'

export default ({ data }) => (
  <div className={styles.wrapper}>
    {data.map((r, key) => (
      <article key={key} className={styles.item}>
        <h3
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: r.title,
          }}
        />
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: r.text,
          }}
        />
      </article>
    ))}
  </div>
)
