import React from 'react'
import styles from './logos.module.scss'
import Img from 'gatsby-image'
import gatsbyImageData from './gatsbyImageData'

export default ({ data }) => (
  <div className={styles.logos}>
    {data.map((image, key) => (
      <Img
        key={key}
        className={styles.item}
        fixed={{
          ...gatsbyImageData({
            image: image,
          }),
        }}
        alt={image.alt || 'Gymradio'}
        title={image.title}
        Tag="figure"
        style={{ height: '', width: '' }}
        imgStyle={{ objectPosition: '', objectFit: '' }}
      />
    ))}
  </div>
)
