import React from 'react'
import styles from './albums.module.scss'
import Img from 'gatsby-image'
import gatsbyImageData from './gatsbyImageData'

export default ({ image }) => (
  <Img
    className={styles.item}
    fluid={{
      ...gatsbyImageData({
        image: image,
        sizes: {
          mobile: `0vw`,
          tablet: `0vw`,
          desktop: `0vw`,
        },
        srcSet: [
          {
            w: '360',
            h: '80px',
          },
          {
            w: '480',
            h: '480',
          },
          {
            w: '640',
            h: '225',
          },
          {
            w: '768',
            h: '225',
          },
          {
            w: '800',
            h: '250',
          },
          {
            w: '945',
            h: '225',
          },
          {
            w: '1280',
            h: '321',
          },
          {
            w: '1500',
            h: '321',
          },
          {
            w: '1900',
            h: '321',
          },
          {
            w:'2560',
            h:'400',
          },
        ],
      }),
    }}
    style={{ display: '' }}
    alt={image.alt || 'Gymradio album'}
    title={image.title}
    Tag="figure"
    imgStyle={{ objectPosition: '', objectFit: '' }}
  />
)
